import React, { useEffect, useState } from "react";
import Routes from "../Routes";
import { BrowserRouter as Router } from "react-router-dom";
import SessionProvider from "../../contexts/session";
import WorkspaceContextProvider from "../../contexts/workspace";
import ContentContextProvider from "contexts/content";
import NoInternet from "./../Routes/PrivateRoutes/NoInternet";
import CacheBuster from "react-cache-buster";
// import { useClearCache } from "react-clear-cache";
import SpinnerRoot from "../../components/SpinnerRoot/SpinnerRoot";
import { version } from "../../../package.json";
const isProdEnv = process.env.NODE_ENV === "production";
const basename = isProdEnv ? "/" : "";

const App = () => {
  const [online, setOnline] = useState(true);
  // const { isLatestVersion, emptyCacheStorage } = useClearCache();
  // useEffect(() => {
  //   console.log(
  //     "<=================================latest cache================================>",
  //     isLatestVersion
  //   );
  //   if (!isLatestVersion) {
  //     console.log(
  //       "<=================================clearing cache================================>"
  //     );
  //     emptyCacheStorage();
  //   }
  // }, []);

  useEffect(() => {
    let timeout = null;
    const updateOnlineStatus = (isOnline) => {
      setOnline(isOnline);
      timeout = setTimeout(() => callApiNow(), 10000);
    }
    const callApiNow = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      fetch(window.location.origin, { method: 'HEAD' }).then(res => updateOnlineStatus(res.status >= 200 && res.status <= 210))
        .catch(
          err => updateOnlineStatus(false)
        )
    }
    //callApiNow();
  }, []);

  return (
    <div>
      <CacheBuster
        currentVersion={version} // we need to run -> update the version every time before we make a release, refer Readme 
        isEnabled={isProdEnv} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<SpinnerRoot />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        {online ? (
        <SessionProvider>
          <WorkspaceContextProvider>
            <ContentContextProvider>
              <Router basename={basename}>
                <Routes />
              </Router>
            </ContentContextProvider>
          </WorkspaceContextProvider>
        </SessionProvider>
        ) : (
          <NoInternet />
        )}
      </CacheBuster>
    </div>
  );
};

export default App;
