export const GET_CONTENT_LIBRARY_DATA = "GET_CONTENT_LIBRARY_DATA";
export const GET_CONTENT_LIBRARY_DATA_SUCCESS =
  "GET_CONTENT_LIBRARY_DATA_SUCCESS";
export const GET_UNIT_DATA = "GET_UNIT_DATA";
export const GET_UNIT_DATA_SUCCESS = "GET_UNIT_DATA_SUCCESS";
export const SET_DEFAULT_FILTER = "SET_DEFAULT_FILTER";
export const SET_DEFAULT_FILTER_SUCCESS = "SET_DEFAULT_FILTER_SUCCESS";
export const GET_STAAR_PRATICE_TEST = "GET_STAAR_PRATICE_TEST";
export const GET_STAAR_PRATICE_TEST_SUCCESS = "GET_STAAR_PRATICE_TEST_SUCCESS";
export const GET_ITEMS_DATA = "GET_ITEMS_DATA";
export const GET_ITEMS_DATA_SUCCESS = "GET_ITEMS_DATA_SUCCESS";
export const UPDATE_DEFAULT_UNIT_DATA = "UPDATE_DEFAULT_UNIT_DATA";
export const UPDATE_BREADCRUMBS_DATA = "UPDATE_BREADCRUMBS_DATA";
export const UPDATE_SPINNER_VALUE = "UPDATE_SPINNER_VALUE";
export const GET_CORELATION_DOCUMENT_DATA = "GET_CORELATION_DOCUMENT_DATA";
export const GET_CORELATION_DOCUMENT_DATA_SUCCESS =
  "GET_CORELATION_DOCUMENT_DATA_SUCCESS";
export const GET_GRADES_AND_STANDARDS = "GET_GRADES_AND_STANDARDS";
export const GET_GRADES_AND_STANDARDS_SUCCESS =
  "GET_GRADES_AND_STANDARDS_SUCCESS";
export const GET_ITEM_BREAKOUT_LIST = "GET_ITEM_BREAKOUT_LIST";
export const GET_ITEM_BREAKOUT_LIST_SUCCESS = "GET_ITEM_BREAKOUT_LIST_SUCCESS";
export const GET_ENGINEERING_DESIGN_ITEM = "GET_ENGINEERING_DESIGN_ITEM";
export const GET_ENGINEERING_DESIGN_ITEM_SUCCESS =
  "GET_ENGINEERING_DESIGN_ITEM_SUCCESS";
export const GET_SEARCH_ITEM = "GET_SEARCH_ITEM";
export const GET_SEARCH_ITEM_SUCCESS = "GET_SEARCH_ITEM_SUCCESS";
export const SET_SELECTED_CORRELATION_DATA = "SET_SELECTED_CORRELATION_DATA";
export const IS_CORRELATION_URL = "IS_CORRELATION_URL";
export const SET_PREVIEW_ITEM_DATA = "SET_PREVIEW_ITEM_DATA";
export const SET_PREVIEW_ITEM_DATA_SUCCESS = "SET_PREVIEW_ITEM_DATA_SUCCESS";
export const SET_EXTERNAL_RESOURCE_ID = "SET_EXTERNAL_RESOURCE_ID";
export const GET_MYLIBRARY_DATA = "GET_MYLIBRARY_DATA";
export const GET_MYLIBRARY_DATA_SUCCESS = "GET_MYLIBRARY_DATA_SUCCESS";
export const GET_LESSON_ACCESS_DATA = "GET_LESSON_ACCESS_DATA";
export const GET_LESSON_ACCESS_DATA_SUCCESS = "GET_LESSON_ACCESS_DATA_SUCCESS";
export const GET_LESSON_BLOCK_TYPE = "GET_LESSON_BLOCK_TYPE";
export const GET_LESSON_BLOCK_TYPE_SUCCESS = "GET_LESSON_BLOCK_TYPE_SUCCESS";
export const GET_LESSON_BLOCKS = "GET_LESSON_BLOCKS";
export const GET_LESSON_BLOCKS_SUCCESS = "GET_LESSON_BLOCKS_SUCCESS";

