import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  startSpinner,
  stopSpinner,
  showPopUp,
} from "../../../redux/authActions";

import { fetchAssignmentDataSuccess, getAssignedGroupsSuccess, getAssignmentResultSuccess ,} from "../actions/assignmentAction";
import * as actions from "../actions/actionType";
import { doGet, doPost } from "../../../utils/ajaxHelper";
import { url } from "../../../utils/apiEndpoints";

export function* fetchAssignmentData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.assignment.getAssignmentData}?to_date=${
      action.payload.to_date ?? ""
    }&from_date=${action.payload.from_date ?? ""}&user_puid=${
      action.payload.user_puid
    }`;
    const assignmentData = yield call(doGet, fetchURL);
    if (assignmentData?.data?.status === "success") {
      yield put(fetchAssignmentDataSuccess(assignmentData?.data));
    } else {
      yield put(fetchAssignmentDataSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(fetchAssignmentDataSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export function* getAssignedGroups(action) {
  try {
    yield put(startSpinner());  
    const fetchURL = `${url.assignment.getAssignedGroups}?user_puid=${action.payload.user_puid}&lesson_id=${action.payload.lesson_id}`;
    const assignedGroupsData = yield call(doGet, fetchURL);
    if(assignedGroupsData?.data?.status === "success"){
      yield put(getAssignedGroupsSuccess(assignedGroupsData?.data));
    }else{
      yield put(getAssignedGroupsSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignedGroupsSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export function* getAssignmentResult(action) {
  try {
    yield put(startSpinner());  
    const fetchURL = `${url.assignment.getAssignmentResult}?group_puid=${action.payload.group_puid}&lesson_id=${action.payload.lesson_id}&group_assignment_id=${action.payload.group_assignment_id}`;
    const assignmentResultData = yield call(doGet, fetchURL);
    if(assignmentResultData?.data?.status === "success"){
      yield put(getAssignmentResultSuccess(assignmentResultData?.data));
    }else{
      yield put(getAssignmentResultSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignmentResultSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export const getProject = (state) => state.assignment?.assignedGroups;
export function* invokeAssignmentReportSagas(action) {
  yield* getAssignedGroups({ payload: action.payload[0]});
  let project = yield select(getProject);
  yield* getAssignmentResult({
    payload: { ...action.payload[1], group_puid: project?.data[0]?.group_puid },
  });
}


export function* callFetchAssignmentData() {
  yield takeLatest(actions.FETCH_ASSIGNMENT_DATA, fetchAssignmentData);
}

export function* callGetAssignedGroups() {
  yield takeLatest(actions.GET_ASSIGNED_GROUPS, getAssignedGroups);
}

export function* callGetAssignmentResult() {
  yield takeLatest(actions.GET_ASSIGNMENT_RESULT, getAssignmentResult);
}
export function* callinvokeAssignmentReportSagas() {
  yield takeLatest(actions.GET_ASSIGNMENT_RESULT_AND_GROUPS, invokeAssignmentReportSagas);
}

export function* assignmentSaga() {
  yield all([callFetchAssignmentData(),callGetAssignedGroups(),callGetAssignmentResult(),callinvokeAssignmentReportSagas()]);
}
