import * as actions from "../actions/actionTypes";

const initialState = {
  contentLibraryData: null,
  unitData: null,
  defultFilter: null,
  starItemData: null,
  itemData: null,
  defaultUnitData: null,
  breadCrumbsData: null,
  spinner: 0,
  corelationDocumentData: null,
  gradesAndStandardsData: null,
  itemBreakoutList: null,
  engineeringDesignData: null,
  searchItemData: null,
  selectedCorrelationData: null,
  isCorrelationUrl: false,
  previewItemData: null,
  externalResourceID: null,
  myLibraryData: null,
  lessonAccessData: null,
  lessonBlockType: null,
  lessonBlocksData: null,
};
export const contentLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CONTENT_LIBRARY_DATA_SUCCESS:
      return { ...state, contentLibraryData: action.payload };
    case actions.GET_UNIT_DATA_SUCCESS:
      return { ...state, unitData: action.payload?.data };
    case actions.SET_DEFAULT_FILTER_SUCCESS:
      return { ...state, defultFilter: action.payload };
    case actions.GET_STAAR_PRATICE_TEST_SUCCESS:
      return { ...state, starItemData: action.payload };
    case actions.GET_ITEMS_DATA_SUCCESS:
      return { ...state, itemData: action.payload };
    case actions.UPDATE_DEFAULT_UNIT_DATA:
      return { ...state, defaultUnitData: action.payload };
    case actions.UPDATE_BREADCRUMBS_DATA:
      return { ...state, breadCrumbsData: action.payload };
    case actions.UPDATE_SPINNER_VALUE:
      return { ...state, spinner: action.payload };
    case actions.GET_CORELATION_DOCUMENT_DATA_SUCCESS:
      return { ...state, corelationDocumentData: action.payload };
    case actions.GET_GRADES_AND_STANDARDS_SUCCESS:
      return { ...state, gradesAndStandardsData: action.payload };
    case actions.GET_ITEM_BREAKOUT_LIST_SUCCESS:
      return { ...state, itemBreakoutList: action.payload };
    case actions.GET_ENGINEERING_DESIGN_ITEM_SUCCESS:
      return { ...state, engineeringDesignData: action.payload };
    case actions.GET_SEARCH_ITEM_SUCCESS:
      return { ...state, searchItemData: action.payload };
    case actions.SET_SELECTED_CORRELATION_DATA:
      return { ...state, selectedCorrelationData: action.payload };
    case actions.IS_CORRELATION_URL:
      return { ...state, isCorrelationUrl: action.payload };
    case actions.SET_PREVIEW_ITEM_DATA_SUCCESS:
      return { ...state, previewItemData: action.payload };
    case actions.SET_EXTERNAL_RESOURCE_ID:
      return { ...state, externalResourceID: action.payload };
    case actions.GET_MYLIBRARY_DATA_SUCCESS:
      return { ...state, myLibraryData: action.payload };
    case actions.GET_LESSON_ACCESS_DATA_SUCCESS:
      return { ...state, lessonAccessData: action.payload };
    case actions.GET_LESSON_BLOCK_TYPE_SUCCESS:
      return { ...state, lessonBlockType: action.payload };
    case actions.GET_LESSON_BLOCKS_SUCCESS:
      return { ...state, lessonBlocksData: action.payload };
    default:
      return state;
  }
};
